
import {computed, defineComponent, onMounted, ref} from 'vue';
import {IonContent, IonPage, IonRouterOutlet} from '@ionic/vue';
import {Bars3BottomRightIcon, Bars3Icon} from '@heroicons/vue/20/solid';
import WizzardComponent from '@/components/wizzard/WizzardComponent.vue';
import NotifyFeatureComponent from '@/components/contact/NotifyFeatureComponent.vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import useFlowFactory from '@/composable/greeve/useFlowFactory';
import {FlowSubType} from '@/greeve/flow/flow.interface';

export default defineComponent({
	name: 'FlowNewPage',
	components: {
		FooterBar,
		NotifyFeatureComponent,
		IonPage,
		IonContent,
		WizzardComponent,
		Bars3Icon,
		Bars3BottomRightIcon,
		IonRouterOutlet,
	},
	setup() {
		const {getFlowWizzardFactory} = useFlowFactory();
		const isMenuOpen = ref(false);
		const asideClass = computed(() => (isMenuOpen.value
				? 'z-50 pb-0 lg:pb-4 lg:col-span-auto lg:sticky lg:top-0'
				: 'sticky top-8 hidden w-44 shrink-0 lg:block'));
		// : 'z-50 pb-0 lg:pb-4 lg:col-span-auto lg:sticky lg:top-0'));
		const toggleButtonClass = computed(() => (isMenuOpen.value ? '' : ''));
		const menuClass = computed(() => (isMenuOpen.value ? 'menu-open' : 'menu-close'));

		const currentWizzard = ref();

		const toggleMenu = () => {
			isMenuOpen.value = !isMenuOpen.value;
		};

		onMounted(() => {
			currentWizzard.value = getFlowWizzardFactory().createWizzardByType(FlowSubType.WHATSAPP_DEFAULT_NEWSLETTER);
		})
		//TODO if wizzard selected by url load wizzard or show infos and history
		//TODO getAllWizzardTemplates on + Click
		//TODO show my Templates

		return {
			asideClass, isMenuOpen, menuClass, toggleButtonClass, toggleMenu, currentWizzard,
		};
	},
});
